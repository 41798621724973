<!--
 * @Author: your name
 * @Date: 2021-04-15 21:20:03
 * @LastEditTime: 2021-04-15 21:46:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\views\interprise\index.vue
-->
<template>
  <div>
    <navigation msg="我的企业"></navigation>
    <div class="qiyeclass">
      <img src="../../assets/ellipse.png" alt="" />
      <div>
        <div class="qi1">上海振翊软件科技有限公司</div>
        <div class="qi2">本人已入职</div>
      </div>
      <van-icon name="arrow" />
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.qiyeclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.49rem 0.3rem;
  background-color: white;
}
.qiyeclass img {
  display: inline-block;
  width: 1.03rem;
  height: 1.03rem;
}
.q1 {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}
.q2 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55814;
  line-height: 0.3rem;
  padding: 0.1rem;
  text-align: center;
  width: 1.74rem;
  margin-top: 0.17rem;
  background: #ffffff;
  border-radius: .1rem;
  border: 1px solid #f55814;
}
</style>
